import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

const OrderSuccess = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/profile');
        }, 3000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <>
        <Navbar />
            <div class="header-details" style={{ height: '100px', background: 'black' }}></div>
            <div style={{ marginTop: '10rem', marginBottom: '10rem' }}>
                <div className="text-center">
                    <h2>Your order has been successfully placed!</h2>
                    <p>Thank you for your purchase. You will be redirected to your profile shortly.</p>
                    <p>If you are not redirected, click <a href="/profile">here</a>.</p>
                </div>
            </div>
        <Footer />
        </>
    );
};

export default OrderSuccess;
